import React from 'react'
//import { Link } from 'gatsby'

import logo from '../img/logo.jpg'

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-background-white has-text-black-ter">
        <div className="content has-text-centered">
          <img
            src={logo}
            alt="Bankkonsulterna"
          //style={{ width: '14em', height: '10em' }}
          />
        </div>
        <div className="content has-text-centered">
          <p>Bankkonsulterna i Norden AB<br />
            <a href="Tel:0812204660">08-12 20 46 60</a><br />
            kontakt[at]bankkonsulterna.se<br />
            <a href="https://www.linkedin.com/company/bankkonsulterna/">LinkedIn</a><br />
          </p>
        </div>
        <div className="content has-text-left has-background-white has-text-black-ter">
          <div className="container has-background-white has-text-black-ter">
            <div className="columns">
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
